import { useSearchParams } from "react-router-dom";
import TimeSeriesChart, { TimeSeriesChartProps } from '../components/TimeSeriesChart';
import { useEffect } from "react";

export default function Report() {
    useEffect(() => {
        document.title = `Arcadia - Report Output`;
    }, []);

    const [searchParams] = useSearchParams();
    var mdKey = null;

    if (searchParams.get("device")?.toLowerCase() === "implant") {
        mdKey = "IMSerial";
    }

    if (searchParams.get("device")?.toLowerCase() === "mat") {
        mdKey = "MatSerial";
    }

    if (searchParams.get("session")) {
        mdKey = "[session]";
    }

    if (searchParams.get("overnight")) {
        mdKey = "[overnight]";
    }

    if (!mdKey) {
        console.error("Report: device/session not recognized, cannot fetch time series data");
        return(<div>Invalid device type: &quot;{ searchParams.get("device") }&quot;, can't render; sorry.</div>);
    }

    const chartProps:TimeSeriesChartProps = {
        mdKey: mdKey,
        mdValue: searchParams.get("serial")!,
        seriesID: parseInt(searchParams.get("series")!),
        startDate: searchParams.get("start") ?? "",
        endDate: searchParams.get("end") ?? "",
        sessionID: searchParams.get("session") ?? "",
        overnightID: searchParams.get("overnight") ?? "",
    };

    return (
    <>
        <TimeSeriesChart {...chartProps} />
    </>
    );
}
