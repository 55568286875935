import { getEvenRowTableBackground, getOddRowTableBackground } from '../../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { localizeDate } from '../../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

interface SessionEntry {
    session_id: number;
    duration: number;
    first_packet_date: string
    packet_count: number;
};

interface SessionsTableProps {
    sessions: SessionEntry[];
}

export default function SessionsTable(props: SessionsTableProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}>
            <Table
                aria-labelledby="tableTitle"
                width="100%"
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th style={{ width: "90px", padding: '12px 6px' }}>Session</th>
                        <th style={{ width: "auto", padding: '12px 6px' }}>Start</th>
                        <th style={{ width: "90px", padding: '12px 6px' }}>Duration</th>
                        <th style={{ width: "90px", padding: '12px 6px' }}>Packets</th>
                    </tr>
                </thead>
                <tbody>
                    {props.sessions.map((session:any, index:number) => (
                    <tr style={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td >
                            <Typography level="body-sm"><Link to={`/session_detail/${ session.id}`} component={RouterLink}>{ session.id }</Link></Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ localizeDate(session.first_packet_date) }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ session.duration }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ session.packet_count }</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
            </Table>
        </Sheet>
    );
}