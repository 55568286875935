import Typography from '@mui/joy/Typography';
import { useQuery } from '@tanstack/react-query';
import { NetworkHelper } from '../util/NetworkHelper';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Sheet, Table, useColorScheme } from '@mui/joy';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate } from '../utils';
import EventsTable from '../components/overnight/EventsTable';
import SessionsTable from '../components/overnight/SessionsTable';
import OvernightStatsTable from '../components/overnight/OvernightStatsTable';

export default function OvernightDetail() {
    const { id } = useParams();
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    const summaryQuery = useQuery({
        queryKey: ['getOvernightDetail', id],
        queryFn: () => NetworkHelper.getInstance().doGetOvernightSummary(parseInt(id!))
    });

    useEffect(() => {
        document.title = `Arcadia - Overnight Detail #${id}`;
    }, [id]);

    if (!summaryQuery.isSuccess) {
        return <p>Loading...</p>;
    }

    if (!summaryQuery.data.overnight) {
        return <p>Overnight not found</p>;
    }

    return (
    <>
        <Typography level="h2" component="h1">Overnight Detail</Typography>
        <br />
            <table>
                <tbody>
                <tr>
                    <td style={{ width: "47%", verticalAlign: "top" }}>
                    <Typography level="h4" component="h1">Summary</Typography>
                    <Sheet
                        className="OrderTableContainer"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            borderRadius: '1px',
                            borderWidth: '1px',
                            minHeight: 0,
                            flexShrink: 0
                        }}
                    >
                        <Table
                            aria-labelledby="tableTitle"
                            sx={{
                                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '4px',
                                '--TableCell-paddingX': '8px',
                            }}
                        >
                            <tbody>
                            <tr style={{ background: rowEvenBackground }}>
                                <td><Typography level="body-sm">ID</Typography></td>
                                <td><Typography level="body-sm">{ summaryQuery.data.overnight.id }</Typography></td>
                            </tr>
                            <tr style={{ background: rowOddBackground }}>
                                <td><Typography level="body-sm">Implant Serial</Typography></td>
                                <td><Typography level="body-sm">{ summaryQuery.data.overnight.implant_serial }</Typography></td>
                            </tr>
                            <tr style={{ background: rowEvenBackground }}>
                                <td><Typography level="body-sm">Start</Typography></td>
                                <td><Typography level="body-sm">{ localizeDate(summaryQuery.data.overnight.start_time) }</Typography></td>
                            </tr>
                            <tr style={{ background: rowOddBackground }}>
                                <td><Typography level="body-sm">End</Typography></td>
                                <td><Typography level="body-sm">{ localizeDate(summaryQuery.data.overnight.end_time) }</Typography></td>
                            </tr>
                            </tbody>
                        </Table>
                    </Sheet>
                    <br />
                    <Typography level="h4" component="h2">Sessions</Typography>
                    <SessionsTable sessions={summaryQuery.data.sessions}  />
                    </td>
                    <td style={{ width: "6%" }}></td>
                    <td style={{ width: "47%", verticalAlign: "top" }}>
                        <Typography level="h4" component="h2">Stats</Typography>
                        <OvernightStatsTable overnight_id={summaryQuery.data.overnight.id} stats={summaryQuery.data.stats} />
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <br />
                        <Typography level="h4" component="h2">Events</Typography>
                        <EventsTable events={summaryQuery.data.events}  />
                    </td>                    
                </tr>
                </tbody>
            </table>
        </>
        );
    }
