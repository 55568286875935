import { localizeDateShort, pickColorByMode } from '../utils';
import { useMemo, useRef } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, PointElement, Title, LineElement, BarElement, BarController, LineController } from "chart.js";
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { useColorScheme } from '@mui/joy/styles';
import { useQuery } from '@tanstack/react-query';

ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, PointElement, Title, LineElement, BarController, LineController, BarElement );

export interface TimeSeriesChartProps {
    mdKey: string;
    mdValue: string;
    seriesID: number;
    startDate: string;
    endDate: string;
    sessionID: string;
    overnightID: string;
}

export default function TimeSeriesChart(props:TimeSeriesChartProps) {
    const { mode } = useColorScheme();
    const tsnQuery = useQuery({
        queryKey: ['getTimeSeriesNames'],
        queryFn: () => NetworkHelper.getInstance().doGetTimeSeriesNames()
    });

    const chartQuery = useQuery({
        queryKey: ['getTimeSeriesValues', props],
        queryFn: () => NetworkHelper.getInstance().doGetTimeSeriesData(props.mdKey, props.mdValue, props.seriesID, props.startDate, props.endDate, props.sessionID, props.overnightID)
    });

    var dateText = "";

    // Start date but no end date
    if (props.startDate.length > 0 && props.endDate.length === 0) {
        dateText = `/ ${props.startDate} - present`;
    }

    // Start and end date
    if (props.startDate.length > 0 && props.endDate.length > 0) {
        dateText = `/ ${props.startDate} - ${props.endDate}`;
    }

    // End date but no start date
    if (props.startDate.length === 0 && props.endDate.length > 0) {
        dateText = `/ up to ${props.endDate}`;
    }

    if (props.sessionID.length > 0) {
        dateText = `/ Session #${props.sessionID}`;
    }

    if (props.overnightID.length > 0) {
        dateText = `/ Overnight #${props.overnightID}`;
    }

    const populateChartObjects = () => {
        if (!tsnQuery.data || !chartQuery.data) {
            return {};
        }

        const getSeriesName = (id:number):string => {
            if (!tsnQuery.data) {
                return "";
            }

            const series = tsnQuery.data.find((element:any) => element.id === id);
            return series ? series.series_name : "";
        }

        const getSeriesUnit = (id:number):string => {
            if (!tsnQuery.data) {
                return "";
            }

            const series = tsnQuery.data.find((element:any) => element.id === id);
            return series ? series.unit : "";
        }

        const getTitle = ():string => {
            if (props.overnightID.length > 0) {
                return `Overnight #${props.overnightID} / ${getSeriesName(props.seriesID)}`;
            }
            else {
                if (props.sessionID.length > 0) {
                    return `Session #${props.sessionID} / ${getSeriesName(props.seriesID)}`;
                }
                else {
                    return `${props.mdKey} / SN${props.mdValue} / ${getSeriesName(props.seriesID)} ${dateText}`;
                }
            }
        }

        const unit = getSeriesUnit(props.seriesID)

        const options:any = {
            scales: {
                y: {
                    ticks: {
                        color: pickColorByMode(mode!, "#000000", "#ffffff"),
                        callback: function(value:string, index:number, ticks:any) {
                            return `${value} ${unit}`;
                        }
                    },
                    grid: {
                        color: pickColorByMode(mode!, "#E0E0E0", "#202020"),
                    }
                },
                x: {
                    ticks: {
                        color: pickColorByMode(mode!, "#000000", "#ffffff"),
                    },
                    grid: {
                        color: pickColorByMode(mode!, "#E0E0E0", "#202020"),
                    }
                }
            },
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: `${getTitle()}`,
                    color: pickColorByMode(mode!, "#000000", "#ffffff"),
                    font: {
                        size: 24,
                        family: "Inter"
                    }
                },
                tooltip: {
                    callbacks: {
                        title: (item:any): string => {
                            const index = item[0].dataIndex;
                            return `${localizeDateShort(chartQuery.data[index].time_stamp)}\nSession #${chartQuery.data[index].session_id}\n${chartQuery.data[index].series_samples} samples`;
                        }
                    }
                },
                legend: {
                    position: 'top',
                    labels: {
                        color: pickColorByMode(mode!, "#000000", "#ffffff"),
                    },
                    title: {
                        color: 'red'
                    },
                    onClick: (e:any) => {
                        console.warn(`plugins.legend.onClick invoked!`);
                        e.native.stopPropagation()
                    }
                },
            },
            title: {
                display: true,
                text: 'detail'
            }
        };

        const labels:string[] = [];
        const averageData:number[] = [];
        const minMaxData:number[][] = [];

        const data: any = {
            labels: labels,
            datasets: [{
                label: `Average`,
                backgroundColor: pickColorByMode(mode!, "#0000ff", "#40a0ff"),
                data: averageData,
                borderColor: pickColorByMode(mode!, "#0000ff", "#40a0ff"),
                hoverBackgroundColor: pickColorByMode(mode!, "#00ff00", "#00ff00"),
                type: "line",
                pointRadius: 5,
                pointHoverRadius: 8,
                order: 0
            },
            {
                label: `MinMax`,
                backgroundColor: pickColorByMode(mode!, "#a0a0a0", "#404040"),
                borderColor: pickColorByMode(mode!, "#a0a0a0", "#404040"),
                borderWidth: {
                    top: 2,
                    bottom: 2,
                    right: 2,
                    left: 2
                },
                borderSkipped: true,
                hoverBackgroundColor: pickColorByMode(mode!, "#707070", "#707070"),
                data: minMaxData,
                type: "bar",
                order: 1
            },
            ]
        };

        if (chartQuery.data && chartQuery.data.length > 0 ) {
            var seriesMin = chartQuery.data[0].series_min;
            var seriesMax = chartQuery.data[0].series_max;

            for (const row of chartQuery.data) {
                labels.push(localizeDateShort(row.time_stamp));
                averageData.push(row.series_avg);
                minMaxData.push([row.series_min, row.series_max]);

                if (row.series_min < seriesMin) {
                    seriesMin = row.series_min;
                }

                if (row.series_max > seriesMax) {
                    seriesMax = row.series_max;
                }
            }

            // This is going to look terrible, so push min and max away a bit.
            if (seriesMin === seriesMax) {
                seriesMin -= 1;
                seriesMax += 1;
            }

            options.scales.y.min = seriesMin;
            options.scales.y.max = seriesMax;
         }

        return { "data": data, "options": options }
    };

    const onChartClick = (event: any) => {
        const elementsAtEvent = getElementAtEvent(chartRef.current!, event);
        var index = undefined;
        if (elementsAtEvent.length === 0) {
            //logTopProps(chartRef.current!.canvas);
            //logTopProps(chartRef.current!.chartArea);
            //console.log(`event client coords: ${event.clientX}, ${event.clientY}`);
            var chartX = event.clientX - chartRef.current!.canvas.getBoundingClientRect().left;
            var chartY = event.clientY - chartRef.current!.canvas.getBoundingClientRect().top;
            //console.log(`chart coords: ${chartX}, ${chartY}`);
            //console.log(`chart area: ${chartRef.current!.chartArea.left}, ${chartRef.current!.chartArea.top}, ${chartRef.current!.chartArea.right}, ${chartRef.current!.chartArea.bottom}`);

            // Bail out if the click is not in the chart area.
            if (chartX < chartRef.current!.chartArea.left || chartX > chartRef.current!.chartArea.right || chartY < chartRef.current!.chartArea.top || chartY > chartRef.current!.chartArea.bottom) {
                //console.warn(`click not in chart area, we are out`);
                event.stopPropagation();
                return;
            }
           // logTopProps(chartRef.current!.scales['x']);
            const nearest = chartRef.current!.getElementsAtEventForMode(event, 'nearest', {intersect: false, axis: 'x'}, false);
            if (nearest.length > 0) {
                index = nearest[0].index;
                console.log(`found nearest element at index: ${index}`);
            }
            else {
                console.log(`getElementsAtEventForMode returned nothing, oh well`);
            }
            // index = chartRef.current!.scales['x'].getValueForPixel(event.offsetX);
            // console.log(`no elements at event, index: ${index}`);

            // console.log(`event offset coords: ${event.offsetX}, ${event.offsetY}`);

        }
        else {
            index = elementsAtEvent[0].index;
        }

        if (index === undefined) {
            console.log(`no index, we are out`);
            return;
        }

        if (index < 0 || index >= chartQuery.data.length || Number.isNaN(index)) {
            console.log(`index out of range: ${index}; we are out`);
            return;
        }

        const sessionID = chartQuery.data[index].session_id;
        const url = `/session_detail/${sessionID}`;

        window.open(url, '_blank');
    }

    const chartRef = useRef<ChartJS<"line", number[], string>>(null);

    const chartObjects = useMemo(populateChartObjects, [chartQuery.data, tsnQuery.data, mode, dateText, props]);

    if (chartQuery.isSuccess) {
        return (
            <Line
                ref={chartRef}
                data={chartObjects.data}
                options={chartObjects.options}
                onClick={onChartClick}
            />
        );
    }
    else {
        return (
            <div>Loading...</div>
        );
    }
}
