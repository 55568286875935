import { getEvenRowTableBackground, getOddRowTableBackground } from '../../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

type OvernightStatsEntry = {
    series_id: number;
    series_name: string;
    samples: number;
};

interface OvernightStatsTableProps {
    overnight_id: number;               
    stats: OvernightStatsEntry[];
}

export default function MetadataTable(props:OvernightStatsTableProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}>
            <Table
                aria-labelledby="tableTitle"
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
            <thead>
                <tr>
                    <th style={{ width: 40, padding: '12px 6px' }}>Name</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Samples</th>
                </tr>
            </thead>
            <tbody>
            {props.stats.map((row:any, index:number) => (
                    <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td>
                            <Typography level="body-sm"><Link to={`/report?type=singleseries&series=${row.series_id}&overnight=${props.overnight_id}`} component={RouterLink}>{row.series_name}</Link></Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ row.samples }</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
            </Table>
        </Sheet>
    );
}