import Typography from '@mui/joy/Typography';
import { NetworkHelper } from '../util/NetworkHelper';
import { useQuery } from '@tanstack/react-query';
import { Link, Sheet, Table, useColorScheme } from '@mui/joy';
import { useEffect } from 'react';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate } from '../utils';
import { Link as RouterLink } from "react-router-dom";

export default function Overnights() {
    const overnightsQuery = useQuery({
        queryKey: ['getRecentOvernights'],
        queryFn: () => NetworkHelper.getInstance().doSearchOvernights( { "recent": true })
    });

    useEffect(() => {
        document.title = "Arcadia - Midnight Bug Reports";
    }, []);
    
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    if (!overnightsQuery.isSuccess) {
        return <p>Loading...</p>;
    }
    
    return (
        <>
        <Typography level="h2" component="h1">Overnights</Typography>
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
        width: '100%',
        borderRadius: '1px',
        borderWidth: '1px',
        flexShrink: 0,
        overflow: 'auto',
        minHeight: 0,
        marginTop: '16px'
        }}
    >
        <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
        }}
        >
            <thead>
                <tr>
                    <th style={{ width: 10, padding: '12px 6px' }}>ID</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Implant</th>
                    <th style={{ width: 30, padding: '12px 6px' }}>Start</th>
                    <th style={{ width: 30, padding: '12px 6px' }}>End</th>
                </tr>
            </thead>
            <tbody>
            {overnightsQuery.data.map((row:any, index:number) => (
            <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.filename}>
                <td>
                    <Link to={`/overnight_detail/${row.id}`} component={RouterLink} download>{row.id}</Link>
                </td>
                <td>
                    <Typography level="body-sm">{row.implant_serial}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{localizeDate(row.start_time)}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{localizeDate(row.end_time)}</Typography>
                </td>
            </tr>
            ))}
            </tbody>
        </Table>
        </Sheet>
        </>
        );
}
