import { getEvenRowTableBackground, getOddRowTableBackground } from '../../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { localizeEpoch } from '../../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

interface EventEntry {
    index: number;
    session_id: string;
    event_timestamp: number;
    description: string;
};

interface EventsTableProps {
    events: EventEntry[];
}

export default function EventsTable(props: EventsTableProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}>
            <Table
                aria-labelledby="tableTitle"
                width="100%"
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th style={{ width: "90px", padding: '12px 6px' }}>Session</th>
                        <th style={{ width: "240px", padding: '12px 6px' }}>Timestamp</th>
                        <th style={{ width: "auto", padding: '12px 6px' }}>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {props.events.map((log_entry:any, index:number) => (
                    <tr style={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td >
                            <Typography level="body-sm"><Link to={`/session_detail/${ log_entry.session_id}`} component={RouterLink}>{ log_entry.session_id }</Link></Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ localizeEpoch(log_entry.event_timestamp) }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ log_entry.description }</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
            </Table>
        </Sheet>
    );
}